<div class="ov-flow open-reporting theme-inner">
  <div class="bg-colour">
    <ion-skeleton-text *ngIf="!isFrontImageLoaded || !isBackImageLoaded" animated="true" class="img-skelton"></ion-skeleton-text>
    <ng-container>
      <img [ngClass]="{'hide': selectedTab !== 'Open Reporting'}" (load)="onImageLoad('front')" [src]="'../assets/img/Open-reporting-front.jpg'" alt="Open Reporting"/>
      <img [ngClass]="{'hide': selectedTab !== 'Open Reporting'}" (load)="onImageLoad('back')" [src]="'../assets/img/Open-reporting-back.jpg'" alt="Open Reporting"/>
      <img [ngClass]="{'hide': selectedTab !== 'Stop Work Authority'}" (load)="onImageLoad('front')" [src]="'../assets/img/Stop-work-front.jpg'" alt="Stop Work Authority"/>
      <img [ngClass]="{'hide': selectedTab !== 'Stop Work Authority'}" (load)="onImageLoad('back')" [src]="'../assets/img/Stop-work-back.jpg'" alt="Stop Work Authority"/>
    </ng-container>
  </div>
</div>

