export const environment = {
  PRODUCTION: false,
  NAV_TYPE: 'uat',  
  INTRANSIT_ENCRYPTION_ENABLED: true,

  CREW_APP_BACKEND_API_BASE_URL: 'https://app-st-css-uat-we-member.azurewebsites.net/api/v1',
  SIGNALR_WRAPPER_API_BASE_URL: 'https://func-st-css-uat-we.azurewebsites.net/api',
  CSS_FUNC_APP_PUBLIC_API_BASE_URL: 'https://func-st-css-uat-we.azurewebsites.net/api',
  CSS_FUNC_APP_PRIVATE_API_BASE_URL: 'https://func-st-css-uat-we.azurewebsites.net/api',
  
  B2C_CLIENT_ID: 'd607caae-6cc3-4669-a5b4-ab99869c539b',
  B2C_REDIRECT_URI: 'https://css.stoltuat.com/auth',
  B2C_LOGOUT_REDIRECT_URI: 'https://css.stoltuat.com/auth?logout=true',
  B2C_LOGIN_AUTHORITY:'https://stoltuatb2c.b2clogin.com/stoltuatb2c.onmicrosoft.com/B2C_1A_STCSS_SIGNUP_SIGNIN',
  B2C_USER_LOGIN_SCOPE: 'openid offline_access',
  B2C_USER_TOKEN_SCOPE: 'https://stoltuatb2c.onmicrosoft.com/b6296f30-7089-4890-afb6-dcce5f0bccd2/read',
};
