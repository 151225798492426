import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
})
export class TabContentComponent  implements OnInit {

  public title: string = 'Corporate safety - Crew Self Service';
  @Input() isDesktop:boolean =false;
  @Input() selectedTab:string = '';
  isFrontImageLoaded:boolean = false;
  isBackImageLoaded:boolean = false;

  ngOnInit() {}

  onImageLoad(side:string){
    console.log('onImageLoad', side)
    if(side === 'front')
      this.isFrontImageLoaded = true;
    if(side === 'back')
      this.isBackImageLoaded = true;
  }
}
