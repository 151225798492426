export const environment = {
  production: false,
  navType: 'uat',
  b2cToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcG4iOiIzNzM5In0.iyxQTVH_vS1LO03N2M4egQYmEAJn4VT8vJeuwcguW1w",
  API_URL: 'https://app-st-css-uat-we-member.azurewebsites.net/api/v1/',
  INTRANSIT_ENCRYPTION_ENABLED: false,
  ENABLE_LOGIN:true,

  AUTH_API_URL: 'https://func-st-css-uat-we.azurewebsites.net/api',
  B2C_CLIENT_ID: 'd607caae-6cc3-4669-a5b4-ab99869c539b',
  B2C_REDIRECT_URI: 'https://css.stoltuat.com/auth',
  B2C_LOGOUT_REDIRECT_URI: 'https://css.stoltuat.com/auth?logout=true',
  B2C_LOGIN_AUTHORITY:'https://stoltuatb2c.b2clogin.com/stoltuatb2c.onmicrosoft.com/B2C_1A_STCSS_SIGNUP_SIGNIN',
  B2C_USER_LOGIN_SCOPE: 'openid offline_access',
  B2C_USER_TOKEN_SCOPE: 'https://stoltuatb2c.onmicrosoft.com/b6296f30-7089-4890-afb6-dcce5f0bccd2/read',
};
 